// Bootstrap 5 with custom theme
@import "./styles/lux-theme-variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "./styles/lux-theme.scss";
// Font Awesome icons https://fontawesome.com/v5.15/icons?d=gallery&m=free
@import "~font-awesome/scss/font-awesome";
// Swagger UI
@import "~swagger-ui-react/swagger-ui.css";

// App styles
@import "./styles/app.scss";
